<template>
    <div>
        <section class="g-bg-pos-center g-bg-size-cover" style="background-image: url(assets/img/fondo-pf-wide.png);">

            <div class="row no-gutters">
                <div class="col-md-6 order-md-1 g-bg-pf">
                    <div class="g-px-50 g-py-50">
                        <div class=" g-mb-100">
                            <h1 class="g-color-white g-font-weight-600 g-font-size-40 g-letter-spacing-0_5 mb-4">Preguntas frecuentes</h1>
                            <p class="lead g-color-white g-font-weight-400 font-color-subtitle2">Para resolver tus dudas sobre el funcionamiento de la Defensoría del Cliente, aquí podrás encontrar información clara y detallada sobre este servicio.</p>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="container g-px-0 g-pa-60">
            <div class="row">
                <div class="col-lg-4 g-mb-30">
                    <div class="media g-mb-30">
                        <div class="media-body u-shadow-v22 g-bg-gray-light-v5 g-pa-30">
                            <div class="g-mb-15">
                                <h5 class="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0 ">
                                    <span class="d-block g-mr-10 font-color-subtitlename">¿NECESITAS ASISTENCIA?</span>
                                </h5>
                            </div>
                            <hr class="g-my-20" style="border: 1px solid;">

                            <div class="media">
                                <div class="d-flex g-mr-10 font-color-subtitlename">
                                    <span class="u-icon-size--sm g-mt-10 ">
                             <img src="assets/img/icon-phone.svg">
                                  </span>
                                </div>
                                <div class="media-body">
                                    <div class="d-flex justify-content-between g-mt-10">
                                        <p class="m-0">22892 2891</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media media-white g-mt-10">
                                <div class="d-flex g-mr-10 g-mt-20">
                                    <span class="u-icon-size--sm">
                                        <img src="assets/img/icon-clock.svg">
                                             </span>
                                </div>
                                <div class="media-body">
                                    <div class="d-flex justify-content-between g-mt-10 font-color-subtitlename">
                                        <p class="m-0">Lunes - Viernes</p>
                                    </div>
                                    <div class="d-flex justify-content-between g-mt-10 font-color-subtitlename">
                                        <p class="m-0">09:00 hrs - 17:00 hrs.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media g-mt-10 g-mb-20">
                                <div class="d-flex g-mr-10">
                                    <span class="u-icon-size--sm g-mt-10">
                                        <img src="assets/img/icon-envelope.svg">
                                             </span>
                                </div>
                                <div class="media-body">
                                    <div class="d-flex justify-content-between g-mt-10 font-color-subtitlename">
                                        <p class="m-0">Contáctanos a: <a href="mailto:contacto@defensoriadelcliente.cl">contacto@defensoriadelcliente.cl</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 g-mb-30">
                    <div id="accordion-07" class="text-justify u-accordion u-accordion-bg-green u-accordion-color-white" role="tablist" aria-multiselectable="true">
                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-01" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0 g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-01" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="true" aria-controls="accordion-07-body-01">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">¿Qué debo hacer para presentar un reclamo?</span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-01" class="collapse show" role="tabpanel" aria-labelledby="accordion-07-heading-01" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    <p>
                                        Puedes ingresar tu reclamo desde el Home del sitio en el botón "Ingresa un Reclamo" para regístrate como usuario y luego seguir con el flujo de ingreso de reclamo. Si ya estás registrado, puedes ingresar un nuevo reclamo desde el mismo botón "ingresar Reclamo" del Home, o bien, puedes ingresar a tu perfil y desde ahí presionar el botón "Ingresar nuevo Reclamo".
                                        <br/>
                                        <br/>
                                        Haz <a href="assets/doc/DDC-Manual-ingreso-de-reclamos-Usuarios-DDC.pdf" target="_blank">click acá</a> para descargar el manual de usuario.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- End Card -->

                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-02" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0  g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-02" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="false" aria-controls="accordion-07-body-02">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">¿Qué materias quedan excluidas?</span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-02" class="collapse" role="tabpanel" aria-labelledby="accordion-07-heading-02" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    <ul>
                                        <li>Las cuestiones que se encuentren en tramitación en sede judicial o arbitral y/u Organismos Públicos (Como por ejemplo: Superintendencia de Bancos, Sernac, etc.).</li>
                                        <li>Los reclamos que no sean del giro bancario.</li>
                                        <li>Los reclamos en que se persiguen indemnizaciones por lucro cesante, daño moral o responsabilidad extracontractual.</li>
                                        <li>Las cuestiones relacionadas al otorgamiento de un crédito de cualquier naturaleza.</li>
                                        <li>Las cuestiones no susceptibles de ser cuantificadas en dinero.</li>
                                        <li>Hechos que pudieren revestir el carácter de delito.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- End Card -->
                        
                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-03" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0  g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-03" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="false" aria-controls="accordion-07-body-03">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">¿Cuándo puedo acudir al “Sistema de Defensoría del Cliente”?</span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-03" class="collapse" role="tabpanel" aria-labelledby="accordion-07-heading-03" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    <ul>
                                        <li>Cuando el cliente y su banco no hayan podido llegar a un acuerdo frente a una determinada diferencia.</li>
                                        <li>Cuando la resolución del banco ha rechazado un determinado reclamo del cliente.</li>
                                        <li>Cuando el banco no haya dado respuesta a un reclamo del cliente en un plazo de 30 días hábiles.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- End Card -->


                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-04" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0  g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-04" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="false" aria-controls="accordion-07-body-04">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">
                                            ¿Cómo puedo ver el estado de mi reclamo?
                                </span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-04" class="collapse" role="tabpanel" aria-labelledby="accordion-07-heading-04" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    <a @click="registrarUsuario">Registrándose e ingresando</a> a su cuenta en el menú superior de la pantalla. Tenga presente que para ver el estado del reclamo debe conocer el folio y el RUT del reclamante. Al consultar el
                                    estado del reclamo aparece además el Defensor al que fue asignado y el cliente tiene la opción, desde ese formulario, de solicitar su cambio. </div>
                            </div>
                        </div>
                        <!-- End Card -->

                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-05" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0  g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-05" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="false" aria-controls="accordion-07-body-05">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">
                                            ¿Cuándo realizar una consulta?
                                </span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-05" class="collapse" role="tabpanel" aria-labelledby="accordion-07-heading-05" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    Cuando no se ha realizado un reclamo formal ante el banco o no se tiene la certeza de que la discrepancia amerite un reclamo. A diferencia de los reclamos, que son atendidos por los Defensores, las consultas son atendidas por los ejecutivos de Defensoría
                                    del Cliente. La respuesta a la consulta se puede ver directamente en la página presionando el botón “Revisar” que aparece en la parte superior.</div>
                            </div>
                        </div>
                        <!-- End Card -->

                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-06" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0  g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-06" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="false" aria-controls="accordion-07-body-06">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">
                                            ¿Cómo es y cuánto dura el proceso?
                                </span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-06" class="collapse" role="tabpanel" aria-labelledby="accordion-07-heading-06" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    El proceso y la duración dependen de los trámites que tengan lugar de acuerdo a las características del reclamo. Estos aspectos están descritos en el
                                    <a href="assets/doc/reglamento_defensor.pdf" target="_blank">REGLAMENTO DE DEFENSORIA DEL CLIENTE PARA BANCOS E INSTITUCIONES FINANCIERAS.</a> </div>
                            </div>
                        </div>
                        <!-- End Card -->

                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-07" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0  g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-07" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="false" aria-controls="accordion-07-body-07">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">¿Qué hacer cuando mi reclamo ha sido rechazado por forma?</span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-07" class="collapse" role="tabpanel" aria-labelledby="accordion-07-heading-07" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    En caso que el reclamo se encuentre incompleto o falte documentación, usted recibirá un correo, solicitando regularizar su reclamo, para lo cual deberá ingresar a su cuenta en el sitio web de la Defensoría del Cliente (https://www.defensoriadelcliente.cl) y realizar los siguientes pasos:<br/><br/>
                                    1.	Ingrese a su cuenta con su RUT y Contraseña.<br/>
                                    2.	En la sección "Reclamos", presione el hipervínculo "Ver estado".<br/>
                                    3.	Presione el número del Reclamo para visualizar su detalle.<br/>
                                    4.	Diríjase a la sección "Documentos del cliente". <br/>
                                    5.	Reemplace el documento en Estado "Rechazado", para lo cual deberá presionar el botón "Subir" y cargar el antecedente que se encontraba incompleto o faltante.<br/><br/>
                                    En caso de no entregar la información solicitada en un plazo de 3 días hábiles, se cerrará su reclamo. Pero ello no impedirá que a futuro pueda ingresar un nuevo reclamo, una vez que cuente con todos los antecedentes requeridos. 
                                </div>
                            </div>
                        </div>
                        <!-- End Card -->

                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-08" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0  g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-08" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="false" aria-controls="accordion-07-body-08">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">¿Qué hacer cuando el banco propone una solución antes de llevar mi reclamo ante un defensor?</span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-08" class="collapse" role="tabpanel" aria-labelledby="accordion-07-heading-08" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    En algunos casos, el Banco podrá ofrecer al cliente una solución rápida a su problema, la cual podrá ser aceptada o rechazada por el cliente. Para aceptarla, ello debe realizarlo durante los 10 días hábiles siguientes a la presentación. En caso de rechazarla o de no responder en el plazo señalado, el reclamo será asignado a un Defensor.<br/><br/>
                                    ¿Cómo puedo aceptar o rechazar la propuesta del banco?<br/><br/>
                                    Para revisar la propuesta de Banco ingrese a su cuenta en el Sitio Web de Defensoría del Cliente (https://www.defensoriadelcliente.cl) con su RUT y Contraseña, y realice los siguientes pasos:<br/><br/>
                                    1.	En la sección "Reclamos", presione el hipervínculo "Ver estado".<br/>
                                    2.	Presione el número del Reclamo para visualizar su detalle.<br/>
                                    3.	Diríjase a la sección "Documentos defensoría".<br/>
                                    4.	Presione el hipervínculo "Propuesta Banco" para descargar el documento.<br/>
                                    5.	Revise el documento "Propuesta Banco".<br/>
                                    6.	Presione el botón "Aceptar" o "Rechazar", según la decisión que haya tomado.
                                </div>
                            </div>
                        </div>
                        <!-- End Card -->

                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-09" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0  g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-09" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="false" aria-controls="accordion-07-body-09">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">¿Qué hacer cuando el defensor solicita antecedentes adicionales?</span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-09" class="collapse" role="tabpanel" aria-labelledby="accordion-07-heading-09" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    Durante la revisión de su caso, el defensor podrá solicitar antecedentes adicionales, para lo cual el defensor fijará un plazo dentro del cual deberán enviarse dichos antecedentes. Para enviarlos, acceda a su cuenta en el Sitio Web de Defensoría del Cliente (https://www.defensoriadelcliente.cl) con su RUT y Contraseña, y realice los siguientes pasos:<br/><br/>
                                    1.	En la sección "Reclamos", presione el hipervínculo "Ver estado".<br/>
                                    2.	Presione el número del Reclamo para visualizar su detalle.<br/>
                                    3.	Visualice los comentarios ingresados por el Defensor.<br/>
                                    4.	Diríjase a la sección "Documentos cliente".<br/>
                                    5.	Adjunte los antecedentes adicionales solicitados por el Defensor.<br/>
                                    6.	Presione el botón "Enviar Documentos".
                                </div>
                            </div>
                        </div>
                        <!-- End Card -->

                        <!-- Card -->
                        <div class="card-ac rounded-0 g-brd-none">
                            <div id="accordion-07-heading-10" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0  g-font-size-default g-font-weight-700 g-pa-20a mb-0">
                                    <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-07-body-10" data-toggle="collapse" data-parent="#accordion-07" aria-expanded="false" aria-controls="accordion-07-body-10">
                                        <span class="u-accordion__control-icon g-brd-right g-brd-gray-light-v4 g-color-white text-center g-pa-20">
                                  <i class="fa fa-plus g-color-gray-dark-v1"></i>
                                  <i class="fa fa-minus"></i>
                                </span>
                                        <span class="g-pa-20">¿Qué hacer cuando el defensor propone un avenimiento?</span>
                                    </a>
                                </h5>
                            </div>
                            <div id="accordion-07-body-10" class="collapse" role="tabpanel" aria-labelledby="accordion-07-heading-10" data-parent="#accordion-07">
                                <div class="u-accordion__body g-bg-gray-light-v5 g-px-50 g-py-30 font-color-subtitlename">
                                    En la revisión de su caso, el defensor podrá proponer a usted y al Banco un avenimiento, el cual podrá ser aceptado o rechazado por las partes, en caso de rechazo o de no responder dentro de un plazo de 10 días hábiles, el Defensor procederá a emitir un Fallo final, inapelable para ambas partes.<br/><br/>
                                    Para revisar la propuesta de Avenimiento ingrese al Sitio Web de Defensoría del Cliente (https://www.defensoriadelcliente.cl) y realice los siguientes pasos:<br/><br/>
                                    1.	Ingrese a su cuenta con su RUT y Contraseña.<br/>
                                    2.	En la sección "Reclamos", presione el hipervínculo "Ver estado".<br/>
                                    3.	Presione el número del Reclamo para visualizar su detalle.<br/>
                                    4.	Diríjase a la sección "Documentos defensoría".<br/>
                                    5.	Presione el hipervínculo "Avenimiento" para descargar el documento.<br/>
                                    6.	Revise el documento "Avenimiento".<br/>
                                    7.	Presione el botón "Aceptar" o "Rechazar", según la decisión que haya tomado.
                                </div>
                            </div>
                        </div>
                        <!-- End Card -->
                    </div>
                </div>

            </div>

        </section>
    </div>
</template>

<script>
  export default {
    methods: {
        registrarUsuario() {
            this.$router.push({name: 'registrationSelectionUserType'});
        }
    },
    beforeMount () {
        window.scrollTo(0, 0);
    },
  };
</script>

<style lang="scss">
  .font-color-name {
    color: #555;
    font-size:17.5px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
  .font-color-subtitlename {
    font-weight: 400;
    font-size: 0.9rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: #555;
  }
    .font-color-subtitle2 {
    font-weight: 400;
    font-size: 17px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
       color: #fff !important;
  }
  </style>
